.munkatars {
  margin: 25px 25px 0 0;
}
.munkatars img {
  width: 50px;
  object-fit: cover;
  height: 50px;
  border-radius: 50%;
  vertical-align: middle;
}
.munkatars .name {
  margin-left: 0.5rem;
}
.munkatars .nickName {
  font-weight: 500;
}
