nav {
  position: sticky;
  top: 0;
  background: #fff;
  border-bottom: 2px solid #232330;
}
nav ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
nav ul li a {
  display: block;
  padding: 16px;
  background: #fff;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
}
nav ul li a:hover {
  background: #263049;
  color: #fff;
}
