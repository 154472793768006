.program {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  text-transform: uppercase;
  font-size: 1.2rem;
}
.program:hover {
  background: rgba(0, 0, 0, 0.05);
}
.program:active {
  background: rgba(0, 0, 0, 0.2);
}
.program img {
  max-height: 150px;
  max-width: 150px;
  margin-right: 16px;
}
