section {
  padding: 3rem 0 0 0;
}
section h2 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
}
section h3 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.1rem;
  letter-spacing: 0.05rem;
}
section p {
  line-height: 1.5rem;
  text-align: justify;
}
