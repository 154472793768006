#app {
  margin: 0;
  display: grid;
  min-height: 100vh;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 132px auto auto;
  grid-template-areas: "background-top-left logo background-top-right" "background-bottom-left nav background-bottom-right" "left content right";
}
#background {
  background: #3b4a68 url(./bg.jpg);
  background-size: cover;
  grid-row-start: background-top-left;
  grid-column-start: background-top-left;
  grid-row-end: background-bottom-right;
  grid-column-end: background-bottom-right;
}
h1#logo {
  grid-area: logo;
  margin: 0;
  padding: 0;
}
h1#logo img {
  display: block;
  height: auto;
  width: calc(68%);
  max-height: 100px;
  margin: 16px 16px 16px auto;
  object-fit: contain;
  object-position: right top;
}
#nav {
  grid-area: nav;
}
#content {
  grid-area: content;
}
